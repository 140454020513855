.myDemandsManagement {
  font-family: $font-primary;
  font-size: 1.6rem;
  margin: 0 auto;

  &__motif {
    background-color: white;
    padding: 3rem 4rem;
    text-align: center;
    &__select {
      padding: 1rem 4rem;
    }
  }
  &__title {
    text-align: center;
  }
  &__creation {
    display: flex;
    justify-content: flex-end;
  }
  &__body {
    text-align: left;
  }
  &__subtitle {
    font-family: 'DINPro-Bold';
    margin-block-end: 2.5rem;
  }
  &__motive {
    position: relative;
    z-index: 100;
  }
  &__number {
    width: 4rem;
    margin-inline-start: 1rem;
    border: 1px solid map-get($colors, 'primaryDisabled');
    font-size: 1.6rem;

    &:active,
    &:focus {
      border: 1px solid map-get($colors, 'primaryDisabled');
      outline: 1px solid map-get($colors, 'primaryDisabled');
    }
  }
  &__submit_container {
    display: flex;
  }
  &__attachment {
    color: map-get($colors, 'blue2');
    padding: 1rem;
    &__frame {
      border: 1px solid map-get($colors, 'Mischka');
    }
    &__header {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: map-get($colors, 'gray100');
    }
    &__label {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      &__input {
        display: none;
        width: 100%;
        height: 100%;
      }
    }
    &__extensions {
      font-size: 1.4rem;
      margin-left: auto;
      text-align: right;
    }
    &__listItem {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      text-overflow: ellipsis;
    }
    &__button {
      padding: 0.6rem;
      &:hover {
        outline: 1px solid map-get($colors, 'primaryHoverBackground');
      }
    }
    &__remove {
      border: none;
      background: none;
      height: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:active,
      &:focus {
        outline: none;
        border: none;
      }
    }
    @media screen and (min-width: $mobileWidth) {
      &__remove:before {
        content: 'Supprimer';
        padding-right: 0.5rem;
        font-size: 1.4rem;
        color: map-get($colors, 'gray600');
      }
    }
    &__infos {
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
    &__error {
      flex-direction: row;
      &__message {
        color: map-get($colors, 'error');
      }
    }
  }
  &__creationButton {
    width: 23rem;
    &__impersonnation {
      width: 32rem;
    }
  }
  .form__submit {
    width: inherit;
    margin: 0 25%;
  }

  textarea {
    background-color: map-get($colors, 'gray0');
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
    .DateInput_input {
      font-size: 1.6rem;
    }
    .DateInput {
      width: 33rem;
    }
  }
  .CalendarDay {
    &[aria-disabled='true'] {
      background-color: map-get($colors, 'gray2');
      cursor: not-allowed;
    }
  }

  .newPrestationSite {
    color: #767c97;
    float: right;
    margin-right: 2vw;
  }
  .newPrestationSite:hover {
    color: #2b2c2e;
    cursor: pointer;
    text-decoration: underline;
  }

  .totalItems {
    margin-top: 4rem;
  }

  .devis-from {
    .form__submit {
      margin: unset;
      width: fit-content;
      padding: 0 3rem;
      float: unset;
    }
    .devis-step-2 {
      .SingleDatePicker,
      .SingleDatePickerInput {
        height: 5.8rem;
        > button {
          height: 100%;
        }
        .DateInput.DateInput_1 {
          height: 100%;
          .DateInput_input {
            height: 100%;
          }
        }
      }
    }
  }
}

.opacity-0 {
  opacity: 0.01;
}

.translateX-100 {
  transform: translateY(100%);
}

.clearTranslateOpacityWithAnimation {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease-in;
}

@keyframes motifAnim {
  0% {
    transform: translateY(10rem);
  }

  100% {
    transform: translateY(0rem);
  }
}

.motifAnimation {
  animation: motifAnim 1s ease 0s 1 normal forwards;
}

@media screen and (max-width: $mobileWidth) {
  .myDemandsManagement {
    font-size: 1.3rem;
  }
  .SingleDatePickerInput {
    .DateInput_input {
      font-size: 1.3rem !important;
    }
    .DateInput {
      width: 20rem !important;
    }
  }
  .form__submit {
    margin: 0 !important;
  }
}

.demands-table {
  &__customClass {
    min-height: auto;
  }

  &__numDemand {
    min-width: 12rem;
    text-align: center;
  }

  &__motif {
    min-width: 35rem;
  }

  &__adresseSitePrestation {
    min-width: 30rem;
  }

  &__dateCreation {
    min-width: 11rem;
  }

  &__statut {
    min-width: 8rem;
  }

  @media screen and (max-width: $tabletWidth) {
    &__statut {
      min-width: 2rem;
      width: 1% !important;
    }
    .statut {
      width: 1%;
    }
  }

  &__ButtonCell {
    min-width: 12rem;
    display: flex;
    justify-content: center;
  }
}

.demands-created-filled {
  background-color: white;
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);

  .totalItems {
    margin-top: 0rem;
    padding: 1.2rem;
  }

  .demands-table {
    margin-inline: 1.2rem;

    .table-pagination {
      margin-block-end: 2rem;
    }
  }
}

.demands-created-transparent {
  background-color: none;
  box-shadow: none;
}

.invalid-feedback {
  .link-hover {
    text-decoration-line: underline;
    &:hover {
      color: map-get($colors, 'black');
    }
  }
}

.categories {
  text-align: center;
  margin-top: 3rem;
  .title {
    font-family: 'DINPro-bold';
    font-size: 2rem;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: 1rem auto;
  }
  .card-item {
    display: flex;
    position: relative;
    height: auto;
    min-height: 5vh;
    background: map-get($colors, 'white');
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    flex-grow: 1;
    flex-basis: 22%;
    margin: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05),
      0 2px 4px 0 rgba(17, 95, 251, 0.1);
    &:hover {
      cursor: pointer;
      border: 1px solid map-get($colors, 'tableHead');
      background: map-get($colors, 'blueLight');
    }
    input {
      position: absolute;
      left: 0.3vw;
      top: 0.5vh;
    }
  }
  .selected {
    border: 1px solid map-get($colors, 'tableHead');
    background: map-get($colors, 'blueLight');
    transform: scale(0.95);
  }

  @media screen and (max-width: $tabletWidth) {
    .card-item {
      flex-basis: 40%;
    }
  }

  @media screen and (max-width: $mobileWidth) {
    .card-item {
      flex-basis: 100%;
    }
  }
}
