.help-page {
  .needHelp-title {
    font-size: 2.2rem;
    color: map-get($colors, 'blue2');
    font-family: 'DINPro-bold';
  }
  .needHelp-back-link {
    position: relative;
    font-size: 1.2rem;
    font-weight: bold;
    color: map-get($colors, 'blue1');
    &::before {
      content: '';
      transform: rotate(90deg);
      padding: 0 1rem;
      position: absolute;
      left: -0.5rem;
      background-image: url(../../icons/arrow-down.svg);
      background-repeat: no-repeat;
      width: 2rem;
      height: 2rem;
      background-size: contain;
    }
  }

  .needHelp-link {
    &__contact,
    &__tsme,
    &__resetPwd {
      span {
        font-size: 1.6rem;
        color: map-get($colors, 'blue1');
        font-family: 'DINPro-medium';
      }
    }
  }
}
