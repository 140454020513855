@charset "UTF-8";

// FOR INFORMATION, we structure CSS properties in this order :
/* 1/ Positioning */
/* 2/ Display & Box Model */
/* 3/ Color */
/* 4/ Text */
/* 5/ Other */

// 1. Configuration and helpers
@import 'abstracts/variables';

// 2. Vendors
@import 'vendors/bootstrap-overrides';
@import 'vendors/pretty-checkbox';
@import 'vendors/normalize';
@import 'vendors/sds-overrides';

// 3. Base stuff
@import 'base/breakpoints';
@import 'base/amount';
@import 'base/base';
@import 'base/border';
@import 'base/colors';
@import 'base/display';
@import 'base/global';
@import 'base/grid';
@import 'base/icon';
@import 'base/liquid';
@import 'base/material-box';
@import 'base/sizing';
@import 'base/typography';
@import 'base/cards';
@import 'base/box-shadow';
@import 'base/annimation';

// 4. Layout-related sections
@import 'layouts/attachment-institution';
@import 'layouts/billing';
@import 'layouts/billing-synthesis';
@import 'layouts/activity-synthesis';
@import 'layouts/create-organization';
@import 'layouts/create-user-profile';
@import 'layouts/dashboard';
@import 'layouts/errorbody';
@import 'layouts/form';
@import 'layouts/footer';
@import 'layouts/header';
@import 'layouts/layout-connection-with-image';
@import 'layouts/layout-connection';
@import 'layouts/login-create';
@import 'layouts/menu';
@import 'layouts/organization-perimeter';
@import 'layouts/password-modified';
@import 'layouts/profile';
@import 'layouts/screen';
@import 'layouts/screen-container';
@import 'layouts/searchBar';
@import 'layouts/session-expired';
@import 'layouts/user-perimeter';
@import 'layouts/password-modified';
@import 'layouts/email-modified';
@import 'layouts/session-expired';
@import 'layouts/waste-register';
@import 'layouts/newPassword';
@import 'layouts/modify-user-email';
@import 'layouts/user-management';
@import 'layouts/billing-analyse-evolution';
@import 'layouts/activity-analyse-evolution';
@import 'layouts/table-evolution';
@import 'layouts/documents';
@import 'layouts/my-documents';
@import 'layouts/messages-management';
@import 'layouts/create_messages';
@import 'layouts/expand-perimeter';
@import 'layouts/my-demands';
@import 'layouts/demand-details';
@import 'layouts/attestation-valorisation';
@import 'layouts/advanced-reports';
@import 'layouts/_my-communication';
@import 'layouts/super-admin';
@import 'layouts/help';
@import 'layouts/notifications';
// 5. Components
@import 'components/alert';
@import 'components/back-home';
@import 'components/billing-annex';
@import 'components/billing-detail';
@import 'components/button';
@import 'components/button-square-icon';
@import 'components/breadcrumb';
@import 'components/chart/chart-header.scss';
@import 'components/activity-chart/activity-stacked-chart-header';
@import 'components/activity-chart/activity-doughnut-chart-section';
@import 'components/activity-chart/activity-bar-chart-section';
@import 'components/chart/legends-chart-section';
@import 'components/chart/doughnut-chart-section.scss';
@import 'components/checkbox';
@import 'components/collapse-table';
@import 'components/datePicker';
@import 'components/form-radio';
@import 'components/input';
@import 'components/link';
@import 'components/loading';
@import 'components/nested_collapse';
@import 'components/perimeter-filter';
@import 'components/reset-password-form';
@import 'components/smart-input-organization';
@import 'components/smart-list';
@import 'components/smart-select';
@import 'components/search-selected';
@import 'components/search-with-list';
@import 'components/selected-container';
@import 'components/sub_menu';
@import 'components/separator';
@import 'components/table';
@import 'components/table-pagination';
@import 'components/vertical-line';
@import 'components/tooltip-icon';
@import 'components/tooltip-button';
@import 'components/tooltip-icon-with-image';
@import 'components/doughnutChart';
@import 'components/year-selector';
@import 'components/cookies-banner';
@import 'components/textArea';
@import 'components/scroll-list';
@import 'components/toast-message';
@import 'components/preview-toast';
@import 'components/contact-us-form';
@import 'components/user-sign-up-form';
@import 'components/popin';
@import 'components/gauge-chart';
@import 'components/breadCrumbs';
@import 'components/filter';
@import 'components/accordion';
@import 'components/dashboard-suez4you';
@import 'components/session-expiration';
@import 'components/date-time-picker';
@import 'components/maintenance';
@import 'components/file-upload';

// Last position matters!
@import '~react-theme-components/assets/scss/main';
