.attestationValorisation {
    &__form {
        display: flex;
        margin-bottom : 3vh;
        
        &__etablissements {
            width: 45vw;
            margin-right: 2rem;
        }
        
        &__years {
            width: 10vw;
        }
        
        &__label {
            font-family: "DINPro-bold";
            font-size: 1.6rem;
            color: #030f40;
        }
        
    }
    &__listeItems {
        height: 37vh;
        overflow-y : auto;
    }
    &__searchBar_select {
        color: map-get($colors, 'blue1');
        font-family: "DINPro";
        font-size: 1.6rem;
    }
}

  @include respond-down-to-media-breakpoint(small) {
    .attestationValorisation {
        &__form {
            flex-direction: column;

            &__etablissements {
                width: 90vw;
            }
            &__years {
                width: 15vw;
            }
        }
        &__listeItems {
            .nested-collapse__info {
                word-break: break-word;
            }
        }
    }
  }
  @media screen and (max-width: $mobileWidth) {
    .attestationValorisation {
        &__form {
            flex-direction: column;
            
            &__etablissements {
                width: 90vw;
            }
            &__years {
                width: 23vw;
            }
        }
        &__listeItems {
            .nested-collapse__info {
                word-break: break-word;
            }
        }
    }
  }

.selectedContainerChildWidth {
    width: 63rem !important;
}