.wasteRegister {
  display: flex;

  &_marginFooter {
    display: flex;
    margin: 4rem 0rem 5.8rem;
  }

  &__left {
    width: 50%;
    padding: 2rem 1rem;
    margin: 0 1rem;
    background-color: #ffffff;
    .table {
      margin-top: 1.5rem;
      th:not(.selection)  {
        padding-left: 10px;
        &.raisonSociale, &.searchAdresse, &.searchVille, &.searchText{
          .table__sort {
            display: none;
          }
        }
        &.raisonSociale{
          min-width: 204px
        }
        &.searchAdresse{
          min-width: 148px
        }
        &.searchVille{
          min-width: 180px
        }
        &.numClient{
          min-width: 110px;
          .table__filter{
            padding: 12px 6px 6px 6px  !important;
          }
        }
      }
    }
    .filter_module_desktop {
      .apply-filter {
        padding: 0 0.5rem;
      }

      .custom-select-desktop {
        width: 45rem;

        #custom-filter {
          width: 40%;
        }

        .sz-form-group .input-group .sz-form-group__input {
          padding-left: 1rem !important;
          text-align: center;
          width: 50%;
        }
      }

      .tag_block_name {
        font-size: 1.2rem;
        width: 7vw;
        align-items: start;
      }
    }
  }

  &__right {
    width: 50%;
    padding: 2rem 1rem;
  }

  &_exportBtn {
    width: 24rem;
  }

  &_titleSection {
    margin: 0 0 3.2rem 1rem;
    h2 {
      color: map-get($colors, 'primary');
      font-family: $font-primary-medium;
      font-size: 3.2rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 4rem;
      margin-top: 3.75rem;
    }
    span {
      font-size: 1.3rem;
      color: map-get($colors, 'gray1');
      width: 100%;
    }
  }

  &_filterCalendar {
    height: 7.4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 4rem 0 2rem 0;    
  }

  &_filterContainer {
    height: 7.4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-end;
    margin-bottom: 2rem;    
  }

  &_filterContainer:first-of-type {
    .wasteRegister_datePickerContainer {
      margin-left: 1.43rem;
    }
  }

  &_datePickerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;
  }

  &_datePickerlabel {
    margin-left: 1rem;
  }

  &_datePicker {
    width: 31.5rem;
    margin-left: 1rem;
    margin-top: 0.8rem;
  }

  &_perimetreTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &_selectedItemsCount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.8rem;
  }

  &_buttonPosition {
    align-items: flex-end;
  }

  &_iconTitleContainer {
    display: flex;
    align-items: center;
    margin-top: 3.1rem;
  }

  &_icon {
    vertical-align: bottom;
    margin-right: 1.5rem;
  }

  &_border-bottom {
    padding-bottom: 5rem;
    border-bottom: 0.1rem solid map-get($colors, 'gray1FIXME');
  }

  &_leftTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 3rem;
  }

  &_buttonicon {
    margin-right: 1.6rem;
  }

  &__right {
    background-color: map-get($colors, 'white');

    .table {
      
      tbody {

        td {
          &:first-child {
            padding-left: 15px;
          }

          &:nth-child(4) {
            white-space: nowrap;
          }
        }        
      }
    }
  }
  &__warning {
    font-size: 1.8rem;
    height: 4.5rem;
  }

  &__header_selector {
    display: flex;
    margin-bottom: 3.2rem;
    .title {
    color: map-get($colors, 'primary');
    font-family: "DINPro-medium";
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 2rem;
      align-self: center;
    }
  }

  .nested-collapse__header, .nested-collapse__body {

    &.borderColorLvl--1 {
      border-color: map-get($colors, 'green2') !important;
    }
  }

  &_upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5rem 0;
    padding: 3rem 0 2rem 0;
    height: 100%;
    background-color: map-get($colors, 'white');

    &_title {
      color: map-get($colors, 'primary');
      font-size: 2.4rem;
      text-align: center;
      font-family: "DINPro-medium";
    }

    &_info {
      margin: 2rem 0 3rem 0;
      color: map-get($colors, 'blue5');
      font-size: 1.5rem;
      font-family: "DINPro";
      line-height: 1.2rem;
      text-align: center;
      word-spacing: 0.1rem;
    }

    &_error {
      color: map-get($colors, 'error');
      margin-bottom: 4rem;
      font-size: 1.3rem;
      font-family: "DINPro";
      line-height: 1.2rem;
    }

    &_footer {
      width: 80%;
      display: flex;
      justify-content: space-between;
    }
  }

}

.boxListbutton {
  color: map-get($colors, 'primary');
  height: 4.8rem !important;
  width: 22rem !important;
  font-family: $font-primary-medium;
  font-size: 1.4rem !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.8rem;
  text-align: center;
  background: map-get($colors, 'black');
}

@media screen and (max-width: $tabletWidth) {
  .wasteRegister {
    flex-direction: column;
    &__right,
    &__left {
      width: 100%;
      padding: 2rem;
      margin: 1rem;
    }

    &_filterCalendar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }

    &_datePickerContainer {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .wasteRegister {
    flex-direction: column;
    &__right,
    &__left {
      width: 100%;
      padding: 2rem;
    }

    &_filterContainer:first-of-type {
      height: auto;
      margin-bottom: 1rem;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;

      .wasteRegister_datePickerContainer {
        margin-left: 1.45rem;
        margin-bottom: 0;
      }

      .button {
        align-self: center;
      }
    }

    .DateRangePickerInput {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_datePicker {
      width: 100%;
      margin-left: 0;
    }
    &_button {
      width: 100%;
    }
  }
}
