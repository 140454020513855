.filter_module {
  &_bottom-border {
    border-block-end: 1px solid map-get($colors, 'Mischka');
  }

  &_desktop {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1.2rem;
    background-color: white;

    &_filters {
      padding-block-end: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
    }

    &_fixed-height {
      height: var(--max-filter-height);
    }

    .num-demand-desktop {
      width: 13rem;
    }

    .searchBar_select {
      width: 22rem;
    }

    .date-picker-desktop {
      width: 28rem;
    }

    .custom-select-desktop {
      display: flex;
      width: 42rem;
      #custom-filter {
        width: 16rem;
      }
      .sz-form-group,
      #motif-select {
        width: 23rem;
      }
      input {
        background: white;
        border: 1px solid map-get($colors, 'primaryDisabled');
      }
      #site-input,
      #dossier-input {
        padding-left: 1rem !important;
        padding-right: 1rem;
      }
    }

    .filter_buttons {
      display: flex;
      align-items: center;
      margin-top: 2rem;
    }
  }

  &_mobile {
    .modal_filter_button {
      float: right;
    }
  }

  &_tags {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 12fr;
    grid-template-rows: 1fr;
    row-gap: 1rem;
    column-gap: 1.5rem;

    &_container {
      margin-block-start: 1.5rem;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-block-end: 1.5rem;
      border-block-end: 1px solid map-get($colors, 'Mischka');
    }

    &_container::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
    }

    &_container::-webkit-scrollbar-thumb {
      background-color: map-get($colors, 'Mischka');
    }

    .tag_block {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      align-items: center;

      &_name {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-family: $font-primary-medium;
        font-size: 1.6rem;
      }

      &_values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        row-gap: 0.8rem;

        &_item {
          border-color: map-get($colors, 'Mischka');
          padding: 0.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1.5rem;
          font-size: 1.2rem;

          .search-selected__close {
            padding: 0;
            display: flex;
            flex-direction: column;
          }
        }

        span[class='selected-selected__close'] {
          margin-inline-start: 0.5rem;
        }
      }
    }
  }
}

.mobile_filter_modal {
  .modal-dialog {
    margin: 0 1.8rem;
    max-width: 100%;

    .modal-content {
      padding: 2rem;
      height: 80vh;
      overflow-y: scroll;

      .modal-header {
        padding: 0 2rem 1rem 2rem !important;
        align-items: flex-end !important;

        .close {
          font-size: 3rem;
        }

        .modal-title {
          font-weight: bold !important;
          font-size: 1.7rem !important;
        }
      }

      .modal-body {
        height: 70vh;
        display: flex;
        flex-direction: column;

        .filter_module_mobile_filters {
          height: 100%;
          display: flex;
          flex-direction: column;

          .no-top-border {
            border-block-end: none;
          }
        }

        .custom-select-desktop {
          display: flex;
          input {
            background: white;
            border: 1px solid map-get($colors, 'primaryDisabled');
          }
        }

        .row-filter {
          margin: 1rem 0;
        }

        .filter_buttons {
          padding-block: 1.5rem;
          display: flex;
          flex-direction: row;
          margin-top: auto;
          justify-content: center;
        }
      }
    }
  }

  .searchBar_select {
    width: 100%;
  }

  .DateRangePickerInput,
  .DateRangePicker {
    width: 100%;
    text-align: center;
  }

  .DateRangePicker_picker {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}

.mobile_filter_modal.__super-admin {
  .modal-content {
    overflow: scroll;
  }
}

.filter_inputSearch input {
  background: white;
  border: 1px solid map-get($colors, 'primaryDisabled');
}

.add-custom-filter-btn {
  height: 4.7rem;
  outline: none;
  background-color: white;
  border: 1px solid map-get($colors, 'primaryDisabled');
}

.custom-dynamic-filter {
  .sz-form-group .input-group .sz-form-group__input {
    padding-left: 1rem !important;
    padding-right: 3rem !important;
  }
  .custom-select-desktop {
    width: 38rem;
  }
  .DateRangePicker {
    div.DateInput {
      width: 48%;
      .DateInput_input {
        padding: 13px 9px 9px;
        border: unset;
      }
    }
    .DateRangePickerInput_arrow {
      right: 0;
    }
  }
}

.__super-admin.custom-dynamic-filter {
  .filter_module_tags {
    grid-template-columns: 2fr 12fr;
  }

  .searchBar_select {
    width: 20rem !important;
  }

  .input-string {
    width: 16rem;
  }

  .input-dropdown {
    .searchBar_select {
      width: 20rem;
    }
  }
  .row-companyFilter {
    width: 16rem;
  }
}

.__my-demand.custom-dynamic-filter {
  #dynamic-filter {
    width: 16rem;
  }
  .input-string {
    input {
      width: 13rem;
      background: white;
      border: 1px solid map-get($colors, 'primaryDisabled');
    }
  }
}
