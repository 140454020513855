.demand-details {
  --inner-space-xs: 0.5rem;
  --inner-space: 1rem;
  --inner-space-md: 1.8rem;
  --section-space: 2rem;
  --label-color: map-get($colors, 'Mischka');

  > div:not(:first-child) {
    margin-block-start: var(--section-space);
  }

  &__identifier {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__basic .creator {
      width: 100%;
    }

    &__basic .title span {
      font-family: $font-primary-bold;
      font-size: 3.2rem;
      line-height: 3.2rem;
    }

    &__basic .address,
    &__basic .creator {
      margin-block-start: var(--inner-space);
      font-family: $font-primary;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }

    &__side {
      margin-block-start: var(--inner-space-md);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &-action {
        margin-inline-start: var(--inner-space);
      }

      &-status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: var(--label-color);
      }

      &-status .statusValue {
        font-family: $font-primary-bold;
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-block-start: var(--inner-space-xs);
      }
    }

    @media screen and (min-width: $mobileWidth) {
      flex-direction: row;
      justify-content: space-between;

      &__basic {
        flex-grow: 1;
      }

      &__side {
        width: auto;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-status {
          padding-inline-end: var(--inner-space-md);
        }

        &-action {
          padding-inline-start: var(--inner-space-md);
          border-inline-start: 1px solid map-get($colors, 'Mischka');
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, large)) {
      justify-content: space-between;

      &__side {
        .action {
          border-inline-start: 1px solid map-get($colors, 'Mischka');
        }
      }
    }
  }

  &__attributes {
    padding: var(--inner-space-md);
    background-color: white;
    display: flex;
    flex-direction: column;

    .title {
      font-family: $font-primary;
      font-size: 1.3rem;
      line-height: 1.3rem;
      color: var(--label-color);
    }

    .attrValue {
      margin-block-start: 1rem;
      font-family: $font-primary-bold;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    &__basic {
      padding-block-end: var(--inner-space-md);
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: var(--inner-space-xs);

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--inner-space-xs);
      }
    }

    &__dates {
      padding-block-start: var(--inner-space-md);
      border-block-start: 1px solid map-get($colors, 'Mischka');
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: var(--inner-space-md);

      .item {
        width: 50%;
      }
    }

    @media screen and (min-width: $mobileWidth) {
      &__basic {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: none;
        grid-gap: va(--inner-space-md);

        .item {
          gap: var(--inner-space-md);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }

      &__dates {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: va(--inner-space-md);
        gap: 0;
      }
    }

    @media screen and (min-width: map-get($breakpoints, large)) {
      flex-direction: row;

      &__basic {
        width: 65%;
        justify-content: space-between;
        gap: 2rem;

        .item {
          flex-grow: 1;
        }
      }

      &__dates {
        width: 35%;
        border-block-start: none;
        padding-block-start: 0;
        display: flex;
        justify-content: flex-start;
        gap: var(--inner-space-md);

        .item {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: var(--inner-space-md);
        }
      }
    }
  }

  &__description {
    margin-block: var(--inner-space-md);
    padding: var(--inner-space-md);
    background-color: white;
    display: flex;
    flex-direction: column;

    .title {
      font-family: $font-primary-bold;
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    .descriptionValue {
      font-family: $font-primary;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
  .__timeLine_demand {
    font-size: 1.3rem;
    color: map-get($colors, 'primary');
    .origin-ECE {
      background-color: map-get($colors, 'gray100');
      border: 0.6px solid map-get($colors, 'blue4');
      border-radius: 0.8rem;
      box-shadow: 2px 2px 7px map-get($colors, 'Mischka');
      width: 90%;
      margin-left: 10%;
    }

    .origin-CCAP {
      border-radius: 1rem;
      background-color: map-get($colors, 'gray0');
      border: 0.6px solid map-get($colors, 'blue4');
      border-radius: 0.8rem;
      box-shadow: -2px 2px 7px map-get($colors, 'Mischka');
      width: 90%;
    }

    .download-PJ {
      background: transparent;
      border: none;
      outline: none;
    }
    .download-PJ:hover {
      text-decoration: underline;
    }

    .--feed-header {
      font-size: 1.5rem;
      .--username:after {
        content: '|';
        margin: 0 1rem;
        font-size: 1.5rem;
        font-weight: bold;
      }
      .--avatar {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .--all-attachments {
    margin-block: var(--inner-space-md);
    padding: var(--inner-space-md);

    .--attachments-title {
      font-family: $font-primary-bold;
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    .--attachments-body {
      .download-PJ {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: map-get($colors, 'primary');
        font-size: 1.3rem;
        &:hover {
          text-decoration: underline;
        }
      }
      .noPJ {
        color: map-get($colors, 'primary');
        font-size: 1.3rem;
      }
    }

    .__all_files {
      &:nth-child(2n + 1) {
        background-color: map-get($colors, 'gray0');
      }
    }
    .w-80 {
      width: 80%;
    }
    .icon-2 {
      width: 2rem;
      height: 2rem;
    }
  }

  .--comments-header {
    .--comments-title {
      font-family: $font-primary-bold;
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }

  .--comments-body {
    .--comment-sub-title {
      color: map-get($colors, 'blue2');
      font-size: 1.3rem;
    }

    .textArea__container {
      height: auto;
      .textArea__textAreaContainer {
        flex: unset;
        margin-bottom: 1rem;
        textarea {
          background-color: map-get($colors, 'gray0');
          border: 0.6px solid map-get($colors, 'blue4');
          border-radius: 0.3rem;
          font-size: 1.3rem !important;
          outline: none;
          // box-shadow: -2px 2px 7px map-get($colors, 'Mischka');
          &::placeholder {
            font-size: 1.3rem;
          }
        }
      }

      .textArea__footer {
        color: map-get($colors, 'error');
      }
    }

    .generic-button.form__submit {
      padding: 0 3rem;
      width: fit-content;
      min-width: unset;
      border-radius: 0.3rem;
    }
  }

  .no-comment-label {
    font-family: 'DINPro';
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  .open-files-modal {
    background-color: unset;
    outline: none;
    border: none;
    color: map-get($colors, 'primary');
    font-size: 1.3rem;
  }
  .collapse-icon{
    &__description, &__comment {
      &.open{
        img{
          transform: rotate(180deg);
        }
      }
      img{
        transition: .3s ease all;
        width: 60%;
      }
    }
  }
}
