.super-admin {

  &__users {
    &__actionsbutton {
      width : 20vw;
      color: map-get($colors, 'blue1');
      font-family: $font-primary;
      font-size: 1.6rem;

      // sds overrides
      .css-vs1oli-control {
        border-color: map-get($colors, 'blue3');
      }

      &:hover {
        // sds overrides
        .css-vs1oli-control {
          border-color: map-get($colors, 'linkHover');
        }
      }
    }
  }
}

.state-action-popup {
  .edit-users-popup {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  
    .action-label {
      font-family: "DINPro";
      font-size: 1.5rem;
      line-height: 1.4rem;
      font-weight: bold;
    }
  
    li {
      color: #030f40;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
  
  @media screen and (max-width: $tabletWidth) {
    .edit-users-popup {
      flex-direction: column;
      justify-content: space-between;
  
      div:first-child {
        margin-bottom: 2rem;
      }
    }
  }
}