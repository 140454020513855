.maintenance_label {
  font-size: 2.5rem;
  @media screen and (max-width: $mobileWidth) {
    font-size: 1.6rem;
  }
}

.maintenance_status {
  font-size: 1.6rem;
}

.maintenance_section_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 8.3rem;
  padding: 1.2em 2em;
  background-color: white;
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
  @media screen and (max-width: $mobileWidth) {
    padding: 1rem;
  }
  align-items: center;
  margin-bottom: 1rem;
}
