.toast-message {
  border: 0;
  border-radius: 0;
  padding: 1.6rem;
  color: map-get($colors, 'primary');
  font-size: 1.4rem;
  background-color: map-get($colors, 'tabBorderHoverColor');
  z-index: 999;
  min-width: 50rem;
  //text styling
  font-size: 1.4rem !important;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.7rem;

  button {
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
  }
  &__closeIcon {
    height: 1rem;
    width: 1rem;
  }
}
@media screen and (max-width: $mobileWidth) {
  .toast-message {
    min-width: 100%;
  }
}
