.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $buttonHeight;
  padding: 0 2rem;
  border: none;
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  outline: 0;
  transition: all .3s;
  z-index: 1;

  &:active, &:focus {
    outline: 0;
    border: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map-get($colors, 'primaryButtonColor');
    z-index: -2;
  }  

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: map-get($colors, 'primaryHoverBackground');
    transition: all .3s ease;
    z-index: -1;
  }

  &--goTo {
    border: 1px solid map-get($colors, 'gray500');

    &:after {
      background-color: map-get($colors, 'white');
    }
  }

  &:hover {      

    &:before {
      width: 100%;
    }
  }

  &.switch-button {
    background: #fff;
    border: 1px solid map-get($colors, 'tabBorderColor');

    &.billing__switchButton--active {
      background: map-get($colors, 'primary');

      &:hover {
        background: map-get($colors, 'primary');
      }
    }

    &:hover {
      border: 1px solid map-get($colors, 'tabBorderHoverColor');
    }

    &:after, &:before {
      background: none;
    }

    &.active, &.disabled {
      color: #fff;
      border: none;

      &:hover {
        border: none;
      }

      &:after {
        background: map-get($colors, 'primary');        
      }

      &:hover {
        color: map-get($colors, 'primaryButtonColor');
      }
    }
  }

  &:disabled, &.button--inactive {
    background-color: map-get($colors, 'primaryDisabled');
    color: map-get($colors, 'primary');
    cursor: not-allowed;

    &:hover {
      background-color: map-get($colors, 'primaryDisabled');
    }

    &:before, &:after {
      width: 0;
      background: transparent;

      &:hover {
        width: 0;
      }
    }
  }

  &__secondary {
    background: #fff;
    border: 1px solid map-get($colors, 'primaryButtonColor');

    &:hover {
      border-color: map-get($colors, 'primaryHoverBackground');
    }

    &:active, &:focus {
      border: 1px solid map-get($colors, 'primaryHoverBackground');
    }

    &:after, &:before {
      background: none;
    }

    &:disabled {
      background: #fff;
      border-color: map-get($colors, 'primaryDisabled');

      &:hover {
        background-color: inherit;
      }
    }
  }

  &__save {
    @extend .button;
    margin-bottom: 1rem;
    margin-right: 2rem;
  }

  svg {
    margin-right: 2rem;
  }

  & p {
    margin: 0;
  }

  &--inactive {
    background: none;
    color: map-get($colors, 'gray1');
    cursor: not-allowed;

    &:hover {
      background: none;
    }
  }
}

.button_svg_spacing {
  margin-right: 1rem;
}

.modal-footer {
  button {
    height: 32px;
    width: auto;
  }  
}

