.form {
  overflow: auto;
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: #fff;
  box-shadow:
    0 0 1px 0 rgba(17, 95, 251, 0.05),
    0 2px 4px 0 rgba(17, 95, 251, 0.1);
  color: map-get($colors, "primary");

  &_title {
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }
  &_sessionTitle {
    margin-bottom: 2rem;
    font-size: 2rem;
    text-align: center;
    font-family: $font-primary;
  }

  &_error-msg {
    padding: 1rem;
    margin-bottom: 1.8rem;
    text-align: center;
    background-color: map-get($colors, "orange1");
  }

  &_input {
    display: block;
    width: 100%;
    height: 5.8rem;
    border: none;
    // FIXME this color does not exist Design System...
    background-color: #eaedf4;
    font-size: 1.6rem;
    font-family: $font-primary;
    text-indent: 2.1rem;

    &::placeholder {
      color: map-get($colors, "blue1");
    }

    /* hack for chrome */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #eaedf4 inset !important;
    }

    &--error-msg {
      @extend .text-error;
      margin: 0.3rem 0;
      font-size: 1.4rem;
      font-style: italic;
      text-align: right;
    }
  }

  &-input-password {
    display: flex;

    .eye {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      height: 5.8rem;
      margin: auto;
      border: none;
      background: map-get($colors, "tableBorder");
      text-align: center;
      cursor: pointer;
    }
  }

  &__label {
    color: map-get($colors, "primary");
    display: flex;
    margin-bottom: 0.3rem;
    color: map-get($colors, "blue2");
    font-size: 1.6rem;
    font-family: $font-primary-bold;
    letter-spacing: 0.06rem;

    > div:first-child {
      margin-right: 0.4rem;
    }
  }

  &__subinfo {
    margin-top: 0.5rem;
    color: map-get($colors, "blue5");
    font-size: 1.2rem;
    font-family: $font-primary;
    word-spacing: 0.1rem;
  }

  &_advice {
    margin: 1.5rem 0 2rem;
    font-size: 1.4rem;

    .two-col {
      display: flex;

      & ul {
        padding-left: 0;
        list-style: none;
      }

      & li {
        margin-bottom: 1.2rem;
        font-family: $font-primary;
      }

      & svg {
        margin-right: 0.9rem;
      }

      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
    }
  }

  &__submit {
    position: relative;
    float: right;
    min-width: 16.8rem;
    width: 20rem;
    height: $buttonHeight;
    border: none;
    color: map-get($colors, "primary");
    font-size: 1.4rem;
    cursor: pointer;
    outline: 0;
    transition: all 0.3s;
    z-index: 1;

    &:active,
    &:focus {
      outline: 0;
      border: none;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: map-get($colors, "primaryButtonColor");
      z-index: -2;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: map-get($colors, "primaryHoverBackground");
      transition: all 0.3s ease;
      z-index: -1;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }

    &:disabled {
      background-color: map-get($colors, "primaryDisabled");
      color: map-get($colors, "primary");
      cursor: not-allowed;

      &:before,
      &:after {
        width: 0;
        background: transparent;

        &:hover {
          width: 0;
        }
      }
    }

    & svg {
      margin-right: 0.5rem;
    }
  }

  &_tips {
    margin: 0 1.2rem 2rem 0;
    font-size: 1.4rem;
    text-align: right;
  }
  &_bottom {
    display: flex;
    align-items: center;

    &__link {
      flex: 1;
      font-size: 1.4rem;
      margin-left: 1rem;
    }
  }

  &__login-create {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.input_mandatory_indicator {
  text-align: right;
  align-self: flex-end;
  display: inline-block;
  color: map-get($colors, "error");
  font-size: 1.4rem;
  margin-left: 0.1rem;
  height: 1.8rem;
}

.form_create_user_profile {
  display: flex;
  justify-content: center;
  background-color: map-get($colors, "mainBackground");
  margin-bottom: 10rem;

  .form {
    padding: 2rem;

    .spinner-border {
      position: static;
    }
  }
}

@include respond-down-to-media-breakpoint(small) {
  .form_create_user_profile {
    margin: 1rem;

    .form {
      &__submit {
        width: 100%;
      }
    }
  }
  .form {
    &_bottom {
      align-items: flex-end;
      flex-direction: column-reverse;

      &__link {
        margin-top: 1rem;
        margin-right: 1.2rem;
      }
    }
  }
}

.custom-input {
  background: #fff;
  color: map-get($colors, "primary");
  border: 1px solid map-get($colors, "primaryDisabled");
  outline: 0;

  &[value=""] {
    background: map-get($colors, "tableBorder");
    border-color: map-get($colors, "tableBorder");
    color: map-get($colors, "tabBorderColor");

    &:focus {
      background: #fff;
      color: map-get($colors, "primary");
      border-color: map-get($colors, "tabBorderColor");
    }
  }

  &:focus {
    background: #fff;
    color: map-get($colors, "primary");
    border-color: map-get($colors, "tabBorderColor");
  }

  &::placeholder {
    color: map-get($colors, "tabBorderColor");
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
