.contact-us-form {
  &__input {
      flex-grow: 0 !important;
      margin-right: 1rem;
      font-size: 1.6rem;
      font-family: "DINPro";
      margin-top: 12px;
  }
  &__file_input {
      margin-bottom: 1.4rem;
  }
  &_newInputFile {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  &__cgu-block{
    margin-top: 4rem;
  }
  &__footer {
    display: flex;
    flex-direction: column;
  }
  .textArea__header{
    flex: 0!important
  }
  .textArea__container{
    padding: 0.4rem;
  }
  .form {
    padding: 2rem!important;
    box-shadow: none;
  }
  &__mention-info {
    font-size: 1rem;
    font-family: $font-primary-bold;
    font-style: italic;
    text-align: justify;
    padding-top: 2rem;
  }
}
