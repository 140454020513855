.react-datepicker-wrapper {
  width: 100%;
  line-height: 3em;
  font-size: 1.5rem;
  .datepicker-input {
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
    border: 1px solid map-get($colors, 'gray2');
    color: #484848;
  }
  .datepicker-input:focus-visible {
    outline: 0;
    border-bottom: 0.2rem solid map-get($colors, 'tableHead');
  }
}

.react-datepicker-popper {
  z-index: 9 !important;
}

.calendar-content {
  .react-datepicker__month-container {
    width: 35rem;
    float: none;
    .react-datepicker__header {
      background-color: map-get($colors, 'white');
      .react-datepicker__current-month {
        font-size: 1.5rem;
        text-transform: capitalize;
      }
      .react-datepicker__day-names {
        margin: 8px 0 0 0;
        .react-datepicker__day-name {
          width: 4rem;
          font-size: 1.5rem;
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__day {
        width: 4rem;
        line-height: 3rem;
        font-size: 1.5rem;
      }
      .react-datepicker__day--selected {
        background-color: #77b7fc;
      }
      .react-datepicker__day--disabled {
        cursor: not-allowed;
      }
    }
  }
  .react-datepicker__input-time-container {
    width: 100%;
    margin: 0.8rem 0;
    text-align: center;
    line-height: 2.7rem;
    font-size: 1.5rem;
  }
}