.myDocuments {
  padding-bottom: 7rem;
  .button {
    padding: 0;
  }

  &__title {
    margin-bottom: 1.75rem;
    font-family: $font-primary-bold;
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
  &__info {
    color: map-get($colors, 'gray1');
    width: 100%;
    font-family: '$font-primary-medium';
    font-size: 1.6rem;
  }
  &__left {
    width: 0;
    flex-grow: 1;
    margin-right: 2.4rem;
  }
  &__right {
    width: 0;
    flex-grow: 1;
  }
  &__breadcrumb {
    //background-color: map-get($colors, 'gray1') !important;
    padding: 2rem 0rem;

    .breadcrumb {
      padding: 0.75rem 1rem !important; // overriding

      &__item {
        &:hover {
          color: map-get($colors, 'linkHover');
        }
      }

      &__list {
        margin-bottom: 0;
      }
    }
  }
  &_icon {
    width: 3.6rem;
    height: 3.6rem;
  }
  &__organisation {
    background-color: map-get($colors, 'white') !important;
    max-height: 30rem;
    list-style: none;
    overflow-y: auto;

    .borderColorLvl--1 {
      border-color: map-get($colors, 'green2') !important;
    }
  }
  &__table {
    margin-top: 5rem;
  }
  &__nbSelected {
    margin-right: 2rem;
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-right: 2rem;
    }
  }
  &__ButtonCell {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__collapse {
    height: 6rem !important;
  }
  &__emptyState {
    height: 9.7rem !important;
  }

  &__first-row {
    min-height: 5.4rem;
    display: flex;

    & > .myDocuments__right {
      .myDocuments__export-button {
        margin-left: auto;
        box-sizing: border-box;
        height: 4.8rem;
        width: 20.6rem;

        &:hover {
          border-color: map-get($colors, 'action');
        }

        &--active {
          border-color: map-get($colors, 'blue6');
          background-color: map-get($colors, 'blue4');
        }
      }
    }
  }
  &__table {
    margin-top: 1.5rem;

    th:not(.selection) {
      padding-left: 10px;

      &.name {
        min-width: 220px;
      }

      &.docFamilyLabel {
        min-width: 202px;
      }

      &.docTypeLabel {
        min-width: 202px;
      }

      &.createdAt,
      &.expireAt {
        min-width: 180px;
        .table__filter {
          text-align: center;
        }
      }

      &.controlButtons {
        min-width: 136px;
      }
    }

    td {
      .user-controlButtons,
      .user-createdAt {
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: $tabletWidth) {
  .myDocuments {
    &__flex {
      flex-direction: column;
    }
    &__right_evolution,
    &__left,
    &__right {
      width: 100%;
    }
    &__right_evolution,
    &__right {
      margin-top: $mt-small;
    }
    &__stacked-chart {
      min-height: 22rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .myDocuments {
    &__title {
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }

    &__info {
      text-align: justify;
      margin-bottom: 1.8rem;
    }

    &__first-row {
      flex-direction: column;
      align-items: center;

      & > .myDocuments__right {
        margin-bottom: 2rem;
        .myDocuments__export-button {
          margin: auto;
        }
      }
    }
    &__empty-space {
      display: none;
    }

    &__stacked-chart {
      min-height: 20rem;
    }
  }
}

@include respond-down-to-media-breakpoint(small) {
  .myDocuments {
    &__title {
      line-height: 3.6rem;
      margin-bottom: 1.2rem;
    }

    &__info {
      text-align: justify;
      margin-bottom: 1.8rem;
    }

    &__first-row {
      display: block;

      & > .myDocuments__right {
        margin-bottom: 2rem;
        .myDocuments__export-button {
          margin: auto;
        }
      }
    }

    &__empty-space {
      display: none;
    }

    &__stacked-chart {
      min-height: 20rem;
    }
  }
}
