.messages-management {
  display: flex;
  flex-direction: column;

  &__mainContainer {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
  }
  &__switch {
    margin-bottom: 2rem;
  }
  &__switchButton {
    height: 3.6rem !important;
    width: 17.4rem !important;
    margin-right: 0.5rem !important;
    font-family: "DINPro-bold" !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.05rem;
    outline: 0 !important;
    margin-bottom: 2rem;

    &--active {
      background-color: map-get($colors, 'blue7') !important;
      color: map-get($colors, 'white') !important;

      &:hover {
        color: map-get($colors, 'linkHover') !important;
      }
    }
  }
  &__bottomSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
  }
  &__Bottom-header {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7.5rem;
    align-items: center;
  }
  &__tableHeader {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    font-size: 1.6rem;
  }
  &__create-message {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &__buttons {
    width: 22rem;
  }
  &__table {
    margin-top: 1.5rem;
    width: 100%;
    
    th:not(.selection) {
      padding-left: 10px;
      &.createdAt,
      &.expireAt {
        min-width: 180px;
      }
    
      &.statut {
        min-width: 100px;
      }
    
      &.messagetext {
        min-width: 260px;
      }
    
      &.username {
        min-width: 210px;
        .table__sort {
          display: none;
        }
      }
    
      &.company {
        min-width: 190px;
        .table__sort {
          display: none;
        }
      }
    
      &.source {
        min-width: 120px;
      }
    
      &.controlButtons {
        min-width: 52px;
      }
    }
    td {
      .messages-management__createdAtCell,
      .messages-management__expireAtCell {
        text-align: center;
      }
    }tbody {
      td {
        .messages-management__usernameCell {
          > div {
            > span  {
              &.tooltip-icon {
                margin: 0 !important ;
                > svg {
                  height: 1.6rem !important;
                }
              }
            }
            
          }
        }
        .messages-management__companyCell__numDebiteur{
          span  {
            &.tooltip-icon {
              font-style: normal !important;
              margin: 0 !important ;
              > svg {
                height: 1.6rem !important;
              }
            }
          }
          
        }
        
      }
    }
  }
  &__anchor {
    position: absolute;
    top: 15rem;
    left: 0;
  }
  &__usernameCell{
    &__separator{
      border-bottom: 0.1rem solid map-get($colors, 'gray500');
    }
  }
  &__companyCell{
    &__separator{
      border-bottom: 0.1rem solid map-get($colors, 'gray500');
    }
    &__numDebiteur{
      font-style: italic;
    }
    
  }
}
@media screen and (max-width: $mobileWidth) {
  .messages-management {
    &__switch {
      justify-content: center;
      align-items: center;
    }
  }
}
