.perimetreUser {
  .row {
    padding-left: 1.5rem;
  }
  &_title {
    color: map-get($colors, 'primary');
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.6rem;
    margin-top: 3.6rem;
  }
  &_filter {
    @include grid;
  }

  &_stepTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 3.2rem;
    margin-top: 3.6rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 4rem;
  }

  &_perimetreTitle {
    color: #030f40;
    font-family: $font-primary;
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.6rem;
  }

  &_iconTitleContainer {
    display: flex;
    align-items: center;
  }

  &_icon {
    vertical-align: bottom;
    margin-right: 1.5rem;
  }

  &_border-bottom {
    padding-bottom: 5rem;
    border-bottom: 0.1rem solid map-get($colors, 'gray1FIXME');
  }

  &_leftTitle {
    color: map-get($colors, 'primary');
    font-family: $font-primary-medium;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 3rem;
  }

  &_buttonDefault {
    height: 50px !important;
    width: 180px !important;
    color: #030f40;
    font-family: $font-primary-medium;
    font-size: 1.4rem !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.8rem !important;
    text-align: center;
    margin: 1rem;
  }

  &_buttonDisplay {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    align-items: center;
    padding: 0 ;
    padding-bottom: 0.7rem;
    .btn{
      float: right !important;
      margin-right: 0px;
    }

    &.top-section {
      margin-top: 0;

      @include respond-down-to-media-breakpoint(large) {
        margin-top: 3rem;
        flex-direction: column;
      }
    }    
  }
  &_buttonDisplay > button {
    height: 5rem;
  }
  &_search {
    justify-content: space-between;
    margin-top: 3rem;
    align-items: baseline;
  }
  &_card {
    margin-top: 2.3rem;
  }

  &_left {
    background-color: map-get($colors, 'white');
    padding-bottom: 3.6rem;
  }

  &_test {
    display: flex;
    margin-bottom: 0.3rem;
    margin-right: 1.3rem;
  }

  &_test2 {
    width: 100%;
    height: 66px;
    width: 329px;
    justify-content: center;
    //align-items: center;
  }

  &_select {
    height: 58px;
    width: 424px;
    margin-top: 0.6rem;
  }

  &_textSelect {
    color: #030f40;
    font-family: $font-primary-medium;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.7rem;
    padding-left: 2rem;

    & > div {
      display: flex;
      align-items: center;
      .checkbox__label {
        margin-right: 2rem;
      }
    }
  }

  &_tabOrga {
    margin-top: 1.4rem;
    overflow: scroll;
    display: block;
  }
  &_buttonicon {
    margin-right: 1.6rem;
  }
  &__label {
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
  &_button_val{
    .loading__button{
      margin-bottom: 2rem;
    }
  }
}

.boxList_title {
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 2.4rem !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 3rem !important;
  margin-left: 1.7rem;
  margin-top: 2rem;
}

.boxList_selection {
  color: #3a3e46;
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.7rem;
  margin-left: 1.7rem;
}

.boxList_button {
  color: #030f40;
  height: 50px !important;
  width: 185px !important;
  font-family: $font-primary-medium;
  font-size: 1.4rem !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.8rem;
  text-align: center;
  margin-right: 1.4rem;
}

.boxList_numberSelection {
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.7rem;
  margin-top: 1.2rem;
  margin-left: 1.7rem;
  display: block;

  & > div {
    display: flex;
    align-items: center;
    .checkbox__label {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}
.boxList_numberSelection > label {
  display: inline !important;
}

.boxList_list {
  margin-left: 1.7rem;
  height: 266px;
  overflow: scroll;
  background-color: #f7f7f7;
  -ms-overflow-style: none;
}

.boxList_firstPart {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 2.6rem;
  margin-bottom: 2.7rem;
}

.boxList_card {
  display: flex;
  margin-bottom: 0.3rem;
  margin-right: 1.3rem;
  align-items: center;
}

.boxList_name {
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.7rem;
}

.boxList_adresse {
  color: map-get($colors, 'primary');
  font-family: $font-primary-medium;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.7rem;
}

.boxList_icon {
  display: flex;
  margin-left: 1.7rem;
}

@media screen and (max-width: $mobileWidth) {
  .perimetreUser {
    .row {
      padding-left: 0;
    }

    .boxList_firstPart {
      flex-direction: column;
      align-items: center;
      margin-top: 2.6rem;
      margin-bottom: 2.7rem;
    }

    .boxList_numberSelection {
      margin-bottom: 1rem;
      & > div {
        display: flex;
        align-items: center;
        .checkbox__label {
          margin-bottom: 0;
        }
      }
    }
    &_search {
      .perimetreUser_organization,
      .perimetreUser_institution {
        padding: 0;
      }

      .perimetreUser_organization {
        margin-bottom: 1rem;
      }
    }
    &_textSelect {
      margin-bottom: 1.5rem;
      & > div {
        display: flex;
        align-items: center;
        .checkbox__label {
          margin-bottom: 0;
        }
      }
    }

    .smart-select__search {
      min-width: 100%;
    }

    &_buttonDisplay {
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0;

      .perimetreUser_leftTitle {
        margin-bottom: 1rem;
      }

      .btn {
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
  }
}
