.chart-header {
  width: 100%;
  min-height: 9.3rem;
  margin-bottom: 0.5rem;
  padding: 1.2em 2.5em;
  background-color: map-get($colors, 'white');
  box-shadow: 0 0 0.1rem 0 rgba(17, 95, 251, 0.05),
    0 0.2rem 0.4rem 0 rgba(17, 95, 251, 0.1);
  &_analyse-evolution {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-height: 10.3rem !important;

    .chart-header {
      padding: 0 2.5em;

      &__content {
        flex-direction: row;
        flex-flow: wrap;

        > div {
          margin: 0.5em 1em 0.5em 0;
        }
      }
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__total-synthesis {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.75rem;
    margin-top: auto;
    margin-bottom: auto;

    > * {
      display: inline-block;
    }
  }
  &__total-synthesis-evolution {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
    flex: 2;
    align-items: center;
    justify-content: center;
  }

  &__billed {
    font-size: 3.2rem;
    line-height: 4rem;
    white-space: nowrap;
  }
  &__billed-evolution {
    font-size: 2.2rem;
    line-height: 2rem;
    white-space: nowrap;
    flex-direction: row;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__valueperyear {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0.2rem solid map-get($colors, 'blue4');
  }
  &__year-label {
    font-size: 1.4rem;
  }

  &__assets {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: bold;
  }
  &__assets-text {
    font-weight: normal;
  }

  &__supstring {
    vertical-align: super;
    font-size: 1.2rem;
  }

  &__date-range {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  &__date-range-evolution {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    flex-direction: column;
    display: flex;
  }
  &__pourcent-evolution {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-left: 1.5rem;
    padding-left: 1.4rem;
  }
  &_separator {
    margin-left: 5.5rem;
    padding-left: 4.4rem;
    border-left: 0.2rem solid map-get($colors, 'blue4');
  }
  &__second-span {
    margin-top: 0.4rem;
  }
  &__year-range {
    display: flex;
    align-items: center;
  }

  &__year-range {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: $mobileWidth) {
    .chart-header {
      &__content {
        flex-direction: column;
      }
      &__total-synthesis-evolution {
        flex-direction: column;
      }
      &__valueperyear {
        border-right: 0rem;
        margin-top: 1rem;
      }
    }
  }
}

@include respond-down-to-media-breakpoint(large) {
  .chart-header {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__pourcent-evolution {
      border-top: 0rem solid map-get($colors, 'blue4') !important;
      margin-bottom: 0.5rem !important;
    }
  }
}
